import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import logoLight from '../../assets/logo/logo_light.png'
import logoPlasoft from '../../assets/logo/logo_plasoft.png'
import { api } from '../../services/api';
import NumberFormat from 'react-number-format';

export function Footer() {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
    });

    const ano = new Date().getFullYear();

    const [parametros, setParametros] = useState<any>({
        titulo: null,
        email: null,
        fone: null,
        whatsapp: null
    });

    useEffect(() => {
        getParametros();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getParametros() {
        let response: any = await api.get('footer/parametros');
        if (response.status === 200) {
            response = response.data
            if (response.erro > 0) {
                console.log(response.dados);
                Toast.fire(response?.dados || 'Falha ao buscar dados do rodapé', '', 'error');
            } else {

                setParametros(response.dados);
            }
        } else {
            response = response.data
            console.log(response.dados);
            Toast.fire(response?.dados || 'Falha ao buscar dados do rodapé', '', 'error');
        }

    }

    return (

        <div className="mb-0">
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
                </svg>
            </div>

            <div className="landing-dark-bg pt-20">
                <div className="container">
                    <div className="row py-10 py-lg-20">
                        <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                            <div className="rounded border-gray-300 border-dotted p-9 mb-10">
                                <h2 className="text-white">{parametros.titulo}</h2>
                                <span className="fw-normal fs-4 text-white"> {parametros.subtitulo}</span>
                                <br />
                                {parametros.email ?
                                    <span className="fw-normal fs-4 text-white">
                                        <a href="mailto:contato@plasoft.com.br" rel="noreferrer" className="text-white opacity-80 text-hover-primary">
                                            <i className="text-white fa-lg far fa-envelope"></i>  {parametros.email}
                                        </a>
                                    </span>
                                    : <br />}
                                <br />
                                {parametros.fone ?
                                    <span className="fw-normal fs-4 text-white mt-2">
                                        <a href="tel:4932249034" target="_blank" rel="noreferrer" className="text-white opacity-80 text-hover-primary">
                                            <i className="fas text-white  fa-lg fa-phone-square-alt"></i>&nbsp;

                                            <NumberFormat
                                                value={parametros.fone}
                                                displayType={'text'}
                                                isNumericString={true}
                                                format={'(##) ####-####'}
                                            />


                                        </a>
                                    </span> : <br />}
                                <br />
                                {parametros.whatsapp ?
                                    <span className="fw-normal fs-4 text-white mt-2">
                                        <a href="https://wa.me/5549998272447" rel="noreferrer" target="_blank" className="text-white opacity-80 text-hover-primary">
                                            <i className="text-white fa-lg fab fa-whatsapp"></i>&nbsp;
                                            <NumberFormat
                                                value={parametros.whatsapp}
                                                displayType={'text'}
                                                isNumericString={true}
                                                format={'(##) # ####-####'}
                                            />
                                        </a>
                                    </span> : <br />}
                            </div>



                        </div>
                        <div className="col-lg-6 ps-lg-16">
                            <div className="d-flex justify-content-center">
                                <div className="d-flex fw-bold flex-column me-20">
                                    <h4 className="fw-bolder text-white mb-6">Mais do side.cloud</h4>
                                    <a href="http://www.plasoft.com.br/blog" rel="noreferrer" target="_blank" className="text-white  text-hover-primary fs-5 mb-6">Blog</a>
                                    <a href="http://www.plasoft.com.br/clientes" rel="noreferrer" target="_blank" className="text-white  text-hover-primary fs-5 mb-6">Clientes</a>
                                    {/* <a href="#" className="text-white  text-hover-primary fs-5 mb-6">Videos Tutoriais</a> */}
                                    <a href="http://www.plasoft.com.br/suporte" download="Suporte" className="text-white  text-hover-primary fs-5">Ferramenta de Suporte</a>
                                </div>
                                <div className="d-flex fw-bold flex-column ms-lg-20">
                                    <h4 className="fw-bolder text-white mb-6">Continue Conectado</h4>
                                    <a href="http://www.plasoft.com.br/" rel="noreferrer" target="_blank" className="mb-6">
                                        <img src={logoPlasoft} className="h-20px me-2" alt="Plasoft" />
                                        <span className="text-white  text-hover-primary fs-5 mb-6">Plasoft</span>
                                    </a>
                                    <a href="https://www.instagram.com/plasoft/" rel="noreferrer" target="_blank" className="mb-6" >
                                        <img src={process.env.PUBLIC_URL + '/assets/metronic/media/svg/brand-logos/instagram-2-1.svg'} className="h-20px me-2" alt="Instagram" />
                                        <span className="text-white  text-hover-primary fs-5 mb-6">Instagram</span>
                                    </a>
                                    <a href="https://facebook.com/Plasoft" rel="noreferrer" target="_blank" className="mb-6">
                                        <img src={process.env.PUBLIC_URL + '/assets/metronic/media/svg/brand-logos/facebook-4.svg'} className="h-20px me-2" alt="Facebook" />
                                        <span className="text-white  text-hover-primary fs-5 mb-6">Facebook</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="separator separator-dashed border-white"></div>
                <div className="container">
                    <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                        <div className="d-flex align-items-center order-2 order-md-1">
                            <a href="https://trez.cloud" rel="noreferrer">
                                <img alt="Logo" src={logoLight} className="h-15px h-md-20px" />
                            </a>
                            <a className="mx-5 fs-6 fw-bold text-white pt-1" href="http://plasoft.com.br">© {ano} Plasoft</a>
                        </div>
                        <ul className="menu menu-white menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                            <li className="menu-item">
                                <a href="http://www.plasoft.com.br/contato" rel="noreferrer" target="_blank" className="menu-link px-2">Contato</a>
                            </li>
                            <li className="menu-item mx-5" >
                                <a rel="noreferrer" href="https://chat.movidesk.com/ChatWidget/Index/9A2E7D8D99E14FE19FF9BC868672C5B2" target="_blank" className="menu-link px-2">Suporte</a>
                            </li>
                            <li className="menu-item">
                                <a href="https://app.sidecloud.com.br/login" rel="noreferrer" target="_blank" className="menu-link px-2">Contratar</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

