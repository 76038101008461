import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { api } from '../../services/api';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export function Modulos() {

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 4000,
		timerProgressBar: true
	});

	const [parametros, setParametros] = useState<any>({
		titulo: null,
		subtitulo: null
	});

	const [listModulos, setListModulos] = useState<any[]>([]);
	const [listImagens, setListImagens] = useState<any[]>([]);


	useEffect(() => {
		getImagens();
		getParametros();
		getModulos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getModulos() {
		let response: any = await api.get('modulo');
		if (response.status === 200) {
			response = response.data
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire(response?.dados || 'Falha ao buscar módulos', '', 'error');
			} else {
				setListModulos(response.dados);
			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar módulos', '', 'error');
		}

	}

	async function getImagens() {
		let response: any = await api.get('modulo/imagens');
		if (response.status === 200) {
			response = response.data
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire(response?.dados || 'Falha ao buscar imagens dos módulos', '', 'error');
			} else {
				setListImagens(response.dados);

			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar imagens dos módulos', '', 'error');
		}

	}

	async function getParametros() {
		let response: any = await api.get('modulo/parametros');
		if (response.status === 200) {
			response = response.data
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire(response?.dados || 'Falha ao buscar parametros módulos', '', 'error');
			} else {
				setParametros(response.dados);
			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar parâmetros módulos', '', 'error');
		}

	}



	return (
		<>

			<div className="mb-n10 mb-lg-n20 z-index-2">

				<div className="container">

					<div className="text-center mb-17">

						<h3 className="fs-2hx text-dark mb-5" id="modulos" data-kt-scroll-offset="{default: 100, lg: 150}">{parametros.titulo}</h3>

						<div className="fs-5 text-muted fw-bold">{parametros.subtitulo}</div>
					</div>
					<div className="row w-100 gy-10 mb-md-20">
						{listModulos.map(modulo => (
							<div className="col-md-4 px-5" key={modulo.id_landing_modulo}>
								<div className="text-center mb-10 mb-md-0">
									<img src={modulo.imagem} className="mh-125px mb-9" alt="" />
									<div className="d-flex flex-center mb-5">
										<span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">{modulo.ordem}</span>
										<div className="fs-5 fs-lg-3 fw-bolder text-dark">{modulo.titulo}</div>
									</div>
									<div className="fw-bold fs-6 fs-lg-4 text-muted">{modulo.texto}</div>
								</div>
							</div>
						))}
					</div>
					<Carousel
						autoFocus={true}
						autoPlay={true}
						infiniteLoop={true}
						showArrows={true}
						showIndicators={true}
						showThumbs={false}
						swipeable={true}
						transitionTime={200}
						interval={3000}
						showStatus={false}
						useKeyboardArrows
						dynamicHeight={true}>
						{listImagens.map(imagem => (
							<div className="text-center px-5 pt-5 pt-lg-10 px-lg-10" key={imagem.id_landing_modulo_imagem}>
								<img src={imagem.imagem} className="card-rounded shadow mw-100" alt="Imagem Sistema" />
							</div>
						))}
					</Carousel>

				</div>
			</div>
		</>

	)
}