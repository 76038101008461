import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { api } from '../../services/api';



export function Cases() {

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 4000,
		timerProgressBar: true
	});

	const [parametros, setParametros] = useState<any>({
		titulo: null,
		subtitulo: null,
		titulo_banner: null,
		subtitulo_banner: null
	});

	const [listCases, setListCases] = useState<any[]>([])

	useEffect(() => {
		getParametros();
		getCases();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getCases() {
		let response: any = await api.get('case');
		if (response.status === 200) {
			response = response.data
			if (response.erro > 0) {
				Toast.fire(response?.dados || 'Falha ao buscar cases', '', 'error');
			} else {
				setListCases(response.dados);
			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar cases', '', 'error');
		}

	}

	async function getParametros() {
		let response: any = await api.get('case/parametros');
		if (response.status === 200) {
			response = response.data
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire(response?.dados || 'Falha ao buscar parametros cases', '', 'error');
			} else {
				setParametros(response.dados);
			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar parâmetros cases', '', 'error');
		}

	}

	return (
		<div className="mt-20 mb-n20 position-relative z-index-2">
			<div className="container">
				{listCases.length > 0 ? <div className="text-center mb-17">
					<h3 className="fs-2hx text-dark mb-5" id="clients" data-kt-scroll-offset="{default: 125, lg: 150}">{parametros.titulo}</h3>
					<div className="fs-5 text-muted fw-bold">{parametros.subtitulo}</div>
				</div> : <br />}

				<div className="row g-lg-10 mb-10 mb-lg-20">
					{listCases.map((caseSucesso: any) => (
						<div className="col-lg-4" key={caseSucesso.id_landing_case}>
							<div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
								<div className="mb-7">
									<div className="rating mb-6">
										<div className={'rating-label me-2 ' + (caseSucesso.estrelas >= 1 ? 'checked' : '')} >
											<i className="bi bi-star-fill fs-5"></i>
										</div>
										<div className={'rating-label me-2 ' + (caseSucesso.estrelas >= 2 ? 'checked' : '')}>
											<i className="bi bi-star-fill fs-5"></i>
										</div>
										<div className={'rating-label me-2 ' + (caseSucesso.estrelas >= 3 ? 'checked' : '')}>
											<i className="bi bi-star-fill fs-5"></i>
										</div>
										<div className={'rating-label me-2 ' + (caseSucesso.estrelas >= 4 ? 'checked' : '')}>
											<i className="bi bi-star-fill fs-5"></i>
										</div>
										<div className={'rating-label me-2 ' + (caseSucesso.estrelas >= 5 ? 'checked' : '')}>
											<i className="bi bi-star-fill fs-5"></i>
										</div>
									</div>

									<div className="fs-2 fw-bolder text-dark mb-3">{caseSucesso.titulo}</div>

									<div className="text-gray-500 fw-bold fs-4">{caseSucesso.mensagem}</div>
								</div>


								<div className="d-flex align-items-center">
									<div className="symbol symbol-circle symbol-50px me-5">
										<img src={caseSucesso.imagem} className="symbol-label" alt="Autor" />
									</div>

									<div className="flex-grow-1">
										<span className="text-dark fw-bolder text-hover-primary fs-6">{caseSucesso.autor}</span>
										<span className="text-muted d-block fw-bold">{caseSucesso.funcao}</span>
									</div>

								</div>
							</div>
						</div>
					))}

				</div>


				<div className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
					style={{ background: 'linear-gradient(90deg, #006c82 0%, #00d4ff 100%)' }}>

					<div className="my-2 me-5">
						<div className="fs-1 fs-lg-2qx fw-bolder text-white mb-2">{parametros.titulo_banner}</div>
						<div className="fs-6 fs-lg-5 text-white fw-bold opacity-75">{parametros.subtitulo_banner}</div>
					</div>

					<a href="https://app.sidecloud.com.br/login?cadastrar-empresa=1" rel="noreferrer" className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">Teste Gratís</a>

				</div>
			</div>
		</div>
	)
}